import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { TFunction, useTranslation } from 'gatsby-plugin-react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';

import { Order, OrderLines } from 'types/order';
import withOnKeyDown from 'hoc/withOnKeyDown';

import { accountStateSelector } from 'state/account/account.selectors';
import { accountFetchOrderHistoryRoutine, accountFetchOrderLinesRoutine } from 'state/account/account.routines';

import PageLayout from 'components/layouts/page/page.layout';

import { AccordionCustomCtaProps } from 'ui-kit/accordion/accordion.props';
import BackgroundImage from 'ui-kit/background-image/background-image';
import BirdiAccordion from 'ui-kit/accordion/accordion';
import { BlueCircleAlertIcon } from 'ui-kit/icons/alert/alert-icon';
import { BlueCircleHourglassIcon } from 'ui-kit/icons/hourglass-icon/hourglass-icon';
import { BlueCircleShippingIcon } from 'ui-kit/icons/shipping-icon/shipping-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import CtaStack from 'ui-kit/cta-stack/cta-stack';
import PageSection from 'ui-kit/page-section/page-section';

import './order-history.style.scss';

const PAGE_SIZE = 10;

interface OrderInfoCtaProps extends AccordionCustomCtaProps {
    titleIcon?: ReactElement;
    order: Order;
    translation: TFunction<string>;
}
const OrderInfoCta = withOnKeyDown(
    ({ titleIcon, order, isToggled, onClick, onKeyDown, translation }: OrderInfoCtaProps) => {
        return (
            <div className="order-info-cta" onClick={onClick} role="button" tabIndex={0} onKeyDown={onKeyDown}>
                <Row className="no-gutters">
                    <Col xs={2} sm={1} className="order-status-icon-container">
                        {titleIcon}
                    </Col>
                    <Col xs={8} sm={10} className="d-flex align-items-center">
                        <Row className="w-100 no-gutters">
                            <Col xs={12} sm={6} className="">
                                <span className="birdi-accordion-toggle-title-wrapper">
                                    {translation('pages.orderHistory.order.title', { orderDate: order.orderDate })}
                                </span>
                            </Col>
                            <Col xs={12} sm={6} className="d-flex align-items-center">
                                <div className="order-number-container text-left text-sm-right w-100">
                                    <span className="order-number-label">
                                        {translation('pages.orderHistory.order.orderInfoCta.orderNumberLabel')}
                                    </span>
                                    <span>{order && order.orderInvoiceNumber}</span>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={2} sm={1} className="d-flex align-items-center">
                        <ChevronIcon
                            style={{ marginLeft: 'auto', marginRight: 'auto' }}
                            direction={isToggled ? 'up' : 'down'}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
);

export interface OrderProps {
    order: Order;
    onGetOrderLines: (orderNum: string) => void;
    translation: TFunction<string>;
}
const OrderInfo = ({ order, onGetOrderLines, translation }: OrderProps) => {
    const renderOrderDetails = () => {
        return (
            <div className="order-history-order-info">
                <div className="order-details-section-header d-flex align-items-center mb-3">
                    <div className="blue-half-pill mr-1" style={{ height: '0.8rem', width: '0.8rem' }} />
                    <div className="order-details-section-title">
                        {translation('pages.orderHistory.order.orderDetails.title')}
                    </div>
                </div>
                <Row className="order-details-container">
                    <Col>
                        <div>
                            {order &&
                                order.shipDate &&
                                translation('pages.orderHistory.order.orderDetails.shipped', {
                                    shipDate: order.shipDate
                                })}
                        </div>
                        <div>
                            {order &&
                                order.shippingTrackingNumber &&
                                order.shippingTrackingNumber.length > 7 &&
                                translation('pages.orderHistory.order.orderDetails.trackingNumber', {
                                    shippingTrackingNumber: order.shippingTrackingNumber
                                })}
                        </div>
                        <div className="text-capitalize">
                            {translation('pages.orderHistory.order.orderDetails.delivered', {
                                orderStatusDesc: order.orderStatusDesc ? order.orderStatusDesc.toLowerCase() : ''
                            })}
                        </div>
                        <div className="order-total">
                            {translation('pages.orderHistory.order.orderDetails.orderTotal', {
                                orderAmount: order.orderAmount
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const renderPrescriptionDetails = ({ lines, key }: { lines: OrderLines; key: string }) => {
        const renderRxCost = () => {
            switch (order.orderStatusDesc) {
                case 'CLOSED':
                case 'CANCELED': {
                    return translation('pages.orderHistory.order.prescriptionDetails.costNotApplicable');
                }
                default: {
                    return translation('pages.orderHistory.order.prescriptionDetails.cost', {
                        patientCopay: lines.patientCopay
                    });
                }
            }
        };

        const transformDoctorName = (doctorName: string) => {
            if (!doctorName) return '';

            let names = doctorName.split(',');
            for (let i in names) {
                names[i] = names[i].trim();
            }
            names[0] = names[0] + ',';
            return names.join(' ').toLowerCase();
        };

        return (
            <div key={key} className="prescription-details">
                <div className="order-details-section-header d-flex align-items-center mb-3">
                    <div className="blue-half-pill mr-1" style={{ height: '0.8rem', width: '0.8rem' }} />
                    <div className="order-details-section-title">
                        {translation('pages.orderHistory.order.prescriptionDetails.title')}
                    </div>
                </div>
                <Row className="order-details-container">
                    <Col xs={5} lg={4}>
                        <div>{lines.dispensedProductName}</div>
                        <div>
                            {translation('pages.orderHistory.order.prescriptionDetails.quantity', {
                                fillQuantity: lines.fillQuantity
                            })}
                        </div>
                    </Col>
                    <Col xs={7} lg={8}>
                        <div>{renderRxCost()}</div>
                        <div className="text-capitalize">
                            {translation('pages.orderHistory.order.prescriptionDetails.prescriber', {
                                doctorName: lines.doctorName ? transformDoctorName(lines.doctorName) : ''
                            })}
                        </div>
                        <div>
                            {translation('pages.orderHistory.order.prescriptionDetails.rxNumber', {
                                rxNumber: lines.rxNumber
                            })}
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };

    const handleOrderInfoCtaClick = ({ onClick, isToggled }: AccordionCustomCtaProps) => {
        if (onClick) onClick();
        if (onGetOrderLines && !isToggled) onGetOrderLines(order.epostOrderNum);
    };

    const orderStatusIcon = useMemo(() => {
        switch (order.orderStatusDesc) {
            case 'CLOSED':
            case 'CANCELED': {
                return <BlueCircleAlertIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
            case 'SHIPPED WITH RETURNED ITEM(S)':
            case 'SHIPPED WITH REPLACED ITEM(S)':
            case 'SHIPPED': {
                return <BlueCircleShippingIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
            default: {
                return <BlueCircleHourglassIcon style={{ height: '2.5rem', width: '2.5rem' }} />;
            }
        }
    }, [order]);

    return (
        <BirdiAccordion.Toggle
            variant={'full-cta'}
            includeTitleIcon={false}
            toggleIcon={<ChevronIcon />}
            customCta={({ onClick, isToggled }: AccordionCustomCtaProps) => (
                <OrderInfoCta
                    titleIcon={orderStatusIcon}
                    isToggled={isToggled}
                    onClick={() => handleOrderInfoCtaClick({ onClick, isToggled })}
                    order={order}
                    translation={translation}
                />
            )}
        >
            <Row className="no-gutters">
                <Col xs={0} md={1}></Col>
                <Col xs={12} md={10} className="order-details-toggle-container">
                    <Container fluid>
                        <Row>
                            <Col xs={12} lg={7}>
                                {order && order.orderLines && order.orderLines.length === 0 && (
                                    <>
                                        {translation(
                                            'pages.orderHistory.order.prescriptionDetails.messages.noProductInformationAvailable'
                                        )}
                                    </>
                                )}
                                {order && !order.orderLines && (
                                    <>
                                        {translation(
                                            'pages.orderHistory.order.prescriptionDetails.messages.retrievingProductInformation'
                                        )}
                                    </>
                                )}
                                {order &&
                                    order.orderLines &&
                                    order.orderLines
                                        .filter(function (rx) {
                                            return rx.orderLineStatusDesc !== 'CANCELED';
                                        })
                                        .map((rx, i) =>
                                            renderPrescriptionDetails({
                                                lines: rx,
                                                key: `prescription-details-${rx.rxNumber}-${i}`
                                            })
                                        )}
                            </Col>
                            <Col xs={12} lg={5}>
                                {renderOrderDetails()}
                            </Col>
                        </Row>
                    </Container>
                </Col>
                <Col xs={0} md={1}></Col>
            </Row>
        </BirdiAccordion.Toggle>
    );
};

const OrderHistory = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { orderHistory } = useSelector(accountStateSelector);
    const [currentPage, setCurrentPage] = useState(1);

    const hasNextPage = useMemo(() => orderHistory && orderHistory.length === PAGE_SIZE, [orderHistory]);

    const handleGetOrderLines = (orderNum: string) => {
        dispatch(
            accountFetchOrderLinesRoutine.trigger({
                epostOrderNum: orderNum
            })
        );
    };

    const handleNextPageClick = () => {
        setCurrentPage(currentPage + 1);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    const handlePrevPageClick = () => {
        const nextPage = currentPage - 1;
        setCurrentPage(nextPage < 1 ? 1 : nextPage);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };

    const fetchOrderHistory = useCallback(() => {
        dispatch(
            accountFetchOrderHistoryRoutine.trigger({
                from: moment().startOf('year').format('MM.DD.YYYY'),
                to: moment().endOf('year').format('MM.DD.YYYY'),
                page: currentPage.toString(),
                pageSize: PAGE_SIZE
            })
        );
    }, [dispatch, currentPage]);

    useEffect(() => {
        fetchOrderHistory();
    }, [dispatch, currentPage, fetchOrderHistory]);

    useEffect(() => {
        if (!orderHistory) {
            fetchOrderHistory();
        }
    }, [dispatch, orderHistory, fetchOrderHistory]);

    const imageData = useStaticQuery(graphql`
        query {
            blueSkyBackground: file(relativePath: { eq: "assets/images/blue-sky.jpg" }) {
                id
                childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, formats: [AUTO])
                }
            }
        }
    `);

    return (
        <PageLayout metaData={{ nodeTitle: 'Order History' }}>
            <BackgroundImage image={imageData.blueSkyBackground}>
                <PageSection className="px-0 px-md-2">
                    <Row>
                        <Col
                            lg={{
                                span: 10,
                                offset: 1
                            }}
                        >
                            <div className="order-history">
                                <Row>
                                    <Col
                                        xl={{
                                            span: 10,
                                            offset: 1
                                        }}
                                    >
                                        <div className="order-history-header d-flex flex-column align-items-center">
                                            <h5 className="eyebrow-text">{t('pages.orderHistory.eyebrowText')}</h5>
                                            <h2>{t('pages.orderHistory.title')}</h2>
                                            <div className="spacer" />
                                        </div>
                                        <BirdiAccordion className="order-history-accordion">
                                            <>
                                                {!orderHistory && (
                                                    <div className="d-flex justify-content-center mb-5">
                                                        <h5>
                                                            {t('pages.orderHistory.messages.retrievingOrderHistory')}
                                                        </h5>
                                                    </div>
                                                )}
                                                {orderHistory &&
                                                    orderHistory
                                                        .filter(function (order) {
                                                            return (
                                                                order.orderStatusDesc !== 'CANCELED' &&
                                                                // order.orderStatusDesc !== 'CLOSED' &&
                                                                order.orderStatusDesc !== 'OPEN'
                                                            );
                                                        })
                                                        .map((order) => {
                                                            return (
                                                                <OrderInfo
                                                                    key={order.epostOrderNum}
                                                                    order={order}
                                                                    onGetOrderLines={handleGetOrderLines}
                                                                    translation={t}
                                                                />
                                                            );
                                                        })}
                                                {orderHistory && orderHistory.length === 0 && (
                                                    <div className="d-flex justify-content-center mb-5">
                                                        <h5>{t('pages.orderHistory.messages.noOrderHistory')}</h5>
                                                    </div>
                                                )}
                                            </>
                                        </BirdiAccordion>
                                    </Col>
                                </Row>
                                <Row className="pb-5">
                                    <Col
                                        xl={{
                                            span: 10,
                                            offset: 1
                                        }}
                                    >
                                        <Container fluid>
                                            <Row>
                                                <Col
                                                    xs={12}
                                                    lg={{ span: 10, offset: 1 }}
                                                    className="w-100 d-flex justify-content-between"
                                                >
                                                    <CtaStack
                                                        label={t('pages.orderHistory.pagination.prevPage')}
                                                        iconDirection="left"
                                                        onClick={handlePrevPageClick}
                                                        disabled={currentPage === 1}
                                                    />
                                                    <span>
                                                        {t('pages.orderHistory.pagination.currentPage', {
                                                            currentPage
                                                        })}
                                                    </span>
                                                    <CtaStack
                                                        label={t('pages.orderHistory.pagination.nextPage')}
                                                        onClick={handleNextPageClick}
                                                        disabled={!hasNextPage}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </PageSection>
            </BackgroundImage>
        </PageLayout>
    );
};

export default OrderHistory;
